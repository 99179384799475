import firebase from 'firebase/app'
import 'firebase/auth'
// import 'firebase/firestore'
import 'firebase/messaging'
import axios from "utils/axios"
import MyStorage from 'utils/mystorage'
import ReactDOM from 'react-dom'

import NotificationSnack from 'components/My/Notifications/NotificationSnack'

const FBInit = () => {

  if (!firebase.apps.length) {
    firebase.initializeApp(process.env.firebaseConfig)
  } else {
    firebase.app()
  }
  
  if(typeof window !== 'undefined') {
    
    var myCache = new MyStorage()

    if(firebase.messaging.isSupported()) {
      const messaging = firebase.messaging()
      try {
        messaging.usePublicVapidKey("BISZ65nxZKLXJF4xbyWmAAk37JcouI6OeWcymnR6fjrxfCLaHRO2q2IC2LEFbhET4l1WJojTYi2VZsF7F1-IhNo")
      } catch (error) {
      }

      firebase.auth().onAuthStateChanged((user) => {
        if(user) {
            messaging.requestPermission()
            .then(function () {
              return messaging.getToken()
            })
            .then(function (token) {
              if(myCache.get('Token') != token) {
                setTokenSentToServer(false);
              }
              sendTokenToServer(token);
            })
            .catch(function (err) {
              setTokenSentToServer(false);
              console.log("Messaging permission error:")
              console.log(err)
            })
        }
      })
      
      messaging.onMessage(function(payload) {
        console.log("onMessage: ", payload)
        var text = {}

        try {
          if(payload?.data?.notification) {
            text = JSON.parse(payload.data.notification)
          }
          
          if(payload?.notification?.body) {
            text = payload.notification
            text.body = text.body.replace(/\\n/g, "\\n").replace(/\\'/g, "\\'").replace(/\\"/g, '\\"').replace(/\\&/g, "\\&").replace(/\\r/g, "\\r").replace(/\\t/g, "\\t").replace(/\\b/g, "\\b").replace(/\\f/g, "\\f")
            text.title = text.title.replace(/\\n/g, "\\n").replace(/\\'/g, "\\'").replace(/\\"/g, '\\"').replace(/\\&/g, "\\&").replace(/\\r/g, "\\r").replace(/\\t/g, "\\t").replace(/\\b/g, "\\b").replace(/\\f/g, "\\f")
          }          

          var notif = {
            image: text.icon,
            title: text.title,
            text: text.body,
            time: 240000,
            severity: 'info',
            random: Math.random(),
            sound: true
          }
  
          var fbnotification = document.getElementById("fbnotifications")
          const id = Math.random()
          const d = document.createElement("div")
          d.id = id
          fbnotification.appendChild(d)

          ReactDOM.render(
              <NotificationSnack notification={notif} />, d)
        } catch (error) {
          console.log('notification error', error)
        }
        
        return (true)
      })
      
      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          console.log('Token refreshed.');
          setTokenSentToServer(false);
          sendTokenToServer(refreshedToken);
        }).catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
          // showToken('Unable to retrieve refreshed token ', err);
        });
      });
    }

    // Send the Instance ID token your application server, so that it can:
    // - send messages back to this app
    // - subscribe/unsubscribe the token from topics
    async function sendTokenToServer(currentToken) {
      if(firebase.auth().currentUser) {
        if (!isTokenSentToServer()) {
          await axios.post('/api/users', {
            action: 'updatePushToken',
            token: currentToken
          }).then(output => {
            if(output.data == true) {
              setTokenSentToServer(true);
              console.log('wysylam tk')
            } else {
              console.log('tk nz')
            }
          })
          myCache.set('Token', currentToken)
        } else {
          // console.log('Token already sent');
        }
      }
    }

    function isTokenSentToServer() {
      if (myCache.get('sentToServerv2') == 1) {
            return true;
      }
      return false;
    }

    function getTokenSentToServer(token) {
      if (myCache.get('TokenSent')) {
        myCache.set('TokenSent', token)
      };
    }

    function setTokenSentToServer(sent) {
      if (sent) {
        myCache.set('sentToServerv2', 1);
        myCache.get('Token')
      } else {
        myCache.set('sentToServerv2', 0);
        myCache.del('Token');
      }
    }
  }
}

export default FBInit