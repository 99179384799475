import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import Arrows from './arrows.svg'

// function Alert(props) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function NotificationSnack(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [text, setText] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    // props.notification.text = undefined
  };

  useEffect(() => {
    if(props?.notification?.text) {
      setText(props.notification.text)
      setOpen(true)
    }

    if(props?.notification?.text) {
      setText(props.notification.text)
      setOpen(true)
    }

    if (props?.notification?.sound) {
      let audio = new Audio("/notify.mp3")
      audio.play()
    }
  }, [props.notification.text, props.notification.random])

  if(text) {
    var icon = ""
    var image = <></>
    var title = ""
    var anchor = {}

    // if (props?.notification?.anchor) {
    //   anchor = { 
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'center',
    //   }}
    // }

    if (props?.notification?.icon) {
      if (props?.notification?.icon == 'arrows') {
        icon = <img src={Arrows} style={{padding: "1px", background: "#fff", transform: "scaleX(-1)"}} width="15px" height="15px" alt="Arrows" />
      }
    }

    if (props?.notification?.image != null) {
      if (props?.notification?.image) {
        image = <img src={props.notification.image} width="45px" height="45px" style={{margin: "1vw"}} alt="Image" />
      } else {
        image = <img src='/images/logo.png' width="45px" height="45px" style={{margin: "1vw"}} alt="Image" />
      }
    }

    if (props?.notification?.title) {
      title = <AlertTitle>{props.notification.title}</AlertTitle>
    }

    return (
        <Snackbar 
          open={open} 
          autoHideDuration={props?.notification?.time ? props?.notification?.time : 5000} 
          onClose={handleClose}
          {...anchor}
          >
          <>
            {image}
            <Alert elevation={6} variant="filled" onClose={handleClose} severity={props?.notification?.severity ? props?.notification?.severity : "success"}>
              {title}
              {text} &nbsp;
              {props?.notification?.icon ? icon : ""}
            </Alert>
            {image}
          </>
        </Snackbar>
    );
  } else return null
}

{/* 
TransitionComponent={<Slide {...props} direction={props?.notification?.direction ? props.notification.direction : "up"} />}

<Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert> */}
